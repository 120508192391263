import React from 'react';
import { Container, Typography, Grid } from '@mui/material';

const AboutUs = () => {
    return (
        <Container maxWidth="sm" style={{ textAlign: "center", marginTop: '7rem', marginBottom: '2rem', padding: '2rem', backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
            <Typography variant="h3" gutterBottom>
                About Us
            </Typography>
            <Grid container spacing={3} direction="column">
                <Grid item xs={12}>
                    <Typography variant="body1">
                        At CGOATS, we provide top-notch software solutions tailored for your business needs. Our dedicated team of professionals ensures that we leverage the latest technologies and best practices to deliver exceptional results.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        We believe in creating value for our clients by understanding their challenges and crafting innovative solutions that drive success. Your satisfaction is our priority.
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    );
};

export default AboutUs;
