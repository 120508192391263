import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const ContactUs = () => {
    return (
        <Container maxWidth="sm" style={{ textAlign:"center", marginTop: '7rem',marginBottom:'2rem', padding: '2rem', backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
            <Typography variant="h3"  gutterBottom>
                Contact Us
            </Typography>
            <Box style={{ marginBottom: '1.5rem' }}>
                <Typography variant="h6" gutterBottom>
                    Phone: +91 9092560652
                </Typography>
                <Typography variant="h6" gutterBottom>
                    Email: cgoatstech@gmail.com
                </Typography>
                <Typography variant="h6" gutterBottom>
                    Address:
                </Typography>
                <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
                    123 Lorem Ipsum,
                    Suite 500,
                    Business District,
                    Anytown, India 123456
                </Typography>
            </Box>
        </Container>
    );
};

export default ContactUs;
