import React from 'react';
import { Container, Typography, Button, Grid, Card, CardContent } from '@mui/material';
import { motion } from 'framer-motion';
import WebIcon from '@mui/icons-material/Web';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
import DesktopMacIcon from '@mui/icons-material/DesktopMac';
import CloudIcon from '@mui/icons-material/Cloud';
import SearchIcon from '@mui/icons-material/Search';
import CampaignIcon from '@mui/icons-material/Campaign';
import heroImage from '../assets/bg.JPG';

const services = [
    {
        title: 'Website Development',
        description: 'We specialize in creating responsive, user-friendly websites that are tailored to your business needs. Our team utilizes the latest technologies and design principles to ensure your website is not only visually appealing but also optimized for performance and user engagement. Whether you need a simple landing page or a complex web application, we’ve got you covered.',
        icon: <WebIcon style={{ fontSize: 40 }} />,
    },
    {
        title: 'E-commerce Solutions',
        description: 'Our e-commerce solutions provide a comprehensive platform for your online store. From product listing to payment processing, we ensure a seamless shopping experience for your customers. Our solutions are designed with security in mind, ensuring that your customers’ data is protected while providing advanced features like inventory management and customer analytics.',
        icon: <ShoppingCartIcon style={{ fontSize: 40 }} />,
    },
    {
        title: 'Mobile App Development',
        description: 'In today’s mobile-first world, having a robust mobile application is crucial. We develop high-performance mobile apps for both iOS and Android platforms, ensuring a native look and feel. Our development process focuses on user experience, security, and scalability, making sure your app can grow as your business does.',
        icon: <MobileFriendlyIcon style={{ fontSize: 40 }} />,
    },
    {
        title: 'Desktop App Development',
        description: 'We offer custom desktop application development to streamline your business processes. Whether it’s a data management tool or a specialized software solution, our team ensures that your application meets your specific requirements. We leverage the latest technologies to create robust, secure, and efficient desktop applications.',
        icon: <DesktopMacIcon style={{ fontSize: 40 }} />,
    },
    {
        title: 'Cloud Migration',
        description: 'Migrating to the cloud can enhance your business’s efficiency and scalability. Our cloud migration services ensure a smooth transition with minimal disruption to your operations. We assess your existing infrastructure, plan the migration process, and support you through the transition, ensuring that your data and applications remain secure throughout.',
        icon: <CloudIcon style={{ fontSize: 40 }} />,
    },
    {
        title: 'SEO Services',
        description: 'Our SEO experts employ proven strategies to improve your website’s visibility on search engines. We conduct comprehensive keyword research, optimize on-page elements, and build high-quality backlinks to help you rank higher in search results. With our SEO services, you can expect increased organic traffic and better ROI.',
        icon: <SearchIcon style={{ fontSize: 40 }} />,
    },
    {
        title: 'Digital Marketing',
        description: 'We provide a full suite of digital marketing services to help you reach your target audience effectively. From social media management to email marketing and content creation, our strategies are tailored to drive engagement and conversions. Let us help you build a strong online presence and connect with your customers in meaningful ways.',
        icon: <CampaignIcon style={{ fontSize: 40 }} />,
    },
];

const HomePage = () => {
    return (
        <Container style={{marginTop:'4rem'}}>
            {/* Hero Image Section */}
            <motion.div
                initial={{ opacity: 0 }} // Initial opacity for fade-in effect
                animate={{ opacity: 1 }} // Fade in to full opacity
                transition={{ duration: 1 }} // Duration of fade-in
                style={{
                    backgroundImage: `url(${heroImage})`,
                    height: '30vh',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    width: '100vw', // Full width
                    position: 'relative',
                    marginLeft: 'calc(-50vw + 50%)',
                    marginRight: 'calc(-50vw + 50%)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white'
                }}
            >
                {/* Title with Bounce Animation */}
                <motion.div
                    initial={{ y: -80 }} // Start slightly above
                    animate={{ y: 10 }} // Move to original position
                    transition={{ type: 'spring', stiffness: 200 }} // Spring animation for bounce effect
                >
                    <Typography variant="h2" align="center" style={{ padding: '0 16px' }}>
                        Welcome to CGOATS
                    </Typography>
                </motion.div>
            </motion.div>
            <Typography variant="h4" align="center" style={{ margin: '20px 0' }}>
                Your Partner in Software Solutions
            </Typography>
            {/* Services Section */}
            <Typography variant="h5" align="center" style={{ margin: '20px 0' }}>
                Our Services
            </Typography>

            <Grid container spacing={4}>
                {services.map((service) => (
                    <Grid item xs={12} sm={6} md={4} key={service.title}>
                        <motion.div
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            transition={{ type: 'spring', stiffness: 300 }}
                        >
                            <Card>
                                <CardContent>
                                    {service.icon}
                                    <Typography variant="h6" gutterBottom>
                                        {service.title}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {service.description}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </motion.div>
                    </Grid>
                ))}
            </Grid>

            <Grid container justifyContent="center">
                <motion.div
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{ type: 'spring', stiffness: 300 }}
                >
                    <Button variant="contained" color="primary" href="/contact" style={{ margin: '20px 0' }}>
                        Get in Touch
                    </Button>
                </motion.div>
            </Grid>
        </Container>
    );
};

export default HomePage;
