import React from 'react';
import { AppBar, Toolbar, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.svg'; 

const Header = () => {
    return (
        <AppBar position="fixed" style={{ backgroundColor: '#333' }}>
            <Toolbar style={{ justifyContent: 'space-between' }}>
                <img src={logo} alt="Logo" style={{ height: '60px' }} />
                <div>
                    <Button color="inherit" component={Link} to="/">Home</Button>
                    <Button color="inherit" component={Link} to="/about">About Us</Button>
                    <Button color="inherit" component={Link} to="/contact">Contact Us</Button>
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
