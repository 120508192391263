import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import Header from './components/Header';
import Footer from './components/Footer';
 
function App() {
    return (
<Router>
<Header />
<Routes>
<Route path="/" element={<HomePage />} />
<Route path="/about" element={<AboutUs />} />
<Route path="/contact" element={<ContactUs />} />
</Routes>
<Footer />
</Router>
    );
}
 
export default App;