import React from 'react';

import { Box, Typography } from '@mui/material';
 
const Footer = () => {

    return (
<Box component="footer" sx={{ p: 2, backgroundColor: '#333', color: 'white' }}>
<Typography variant="body2" align="center">

                © {new Date().getFullYear()} CGOATS. All rights reserved.
</Typography>
</Box>

    );

};
 
export default Footer;

